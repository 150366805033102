import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { airAuthoringReducersProvider, airAuthoringReducersToken } from '@mhe/air-authoring';
import {
  AssessAuthoringCoreModule,
  AssessAuthoringEnvironment,
  AssessAuthoringRoutingModule,
  AssessEntityListModule,
  AssessState,
  BSX_SDK_CONFIG,
  BsxBentoSdkModule,
  clearStore,
  debug,
  TokenInterceptor,
} from '@mhe/dle-assess-authoring-ui-submodules';
/* TODO (eslint): Fix import */
/* eslint-disable-next-line import/no-deprecated */
import { NgxSharedModule, TranslateModule } from '@mhe/ngx-shared';
import { RichTextEditorModule } from '@mhe/tinymce';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { GlobalTogglesModule } from '@mhe/ol-platform/global-toggles';
import { appInitProvider, globalTogglesConfigFactory } from '../toggles-config/toggles-config';
import { Observable, of } from 'rxjs';
import { Config, IntegrationLauncherModule } from '@mhe/dle-integration-ngx';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const configFactory = (): Observable<Config> =>
  of({ sdlcEnvironment: environment.envName } as Config);

export const metaReducers: MetaReducer<AssessState>[] = environment.production
  ? [clearStore]
  : [clearStore, debug];

export const aauiFactory = (): AssessAuthoringEnvironment => (environment);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSharedModule,
    NgIdleModule.forRoot(),
    TranslateModule.forRoot(),
    RichTextEditorModule.forRoot({
      tinymceUrl: environment.tinymceUrl,
      wirisUrl: environment.wirisUrl,
      mmsUrl: environment.mmsUrl,
      tinymceSkinsUrl: 'https://dle-cdn.mheducation.com/mhe/tinymce/6.8.2/skins/ui/oxide',
      tinymceExternalPlugins: {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        tiny_mce_wiris: `https://dle-cdn.mheducation.com/mhe/wiris/mathtype-tinymce6/plugin.min.js`,
      },
      hideA11yChecker: true,
    }),
    StoreModule.forRoot({} as any, { metaReducers }),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 1000 }),
    StoreModule.forFeature('airAuthoring', airAuthoringReducersToken),

    AssessAuthoringCoreModule.withConfig(aauiFactory),
    AssessAuthoringRoutingModule,
    AssessEntityListModule,
    GlobalTogglesModule.withConfig({
      useFactory: globalTogglesConfigFactory
    }),
    IntegrationLauncherModule.withConfig({
      useFactory: configFactory,
    }),
    BsxBentoSdkModule,
    NgbModule
  ],
  declarations: [AppComponent],
  providers: [
    appInitProvider,
    airAuthoringReducersProvider,
    { provide: 'extConfig', useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: BSX_SDK_CONFIG,
      useValue: {
        metadataEndpoint: environment.bentoUrl
      }
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
